.agentVerification {
  width: 90%;
}
@media only screen and (max-width: 800px) {
  .agentVerification {
    width: 100%;
  }
}

.rejectionReasonsContainer {
  width: 100%;
  background-color: #fff;
  padding: 1rem;
}
