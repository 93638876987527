.ant-card-body {
  /* padding : 0 */
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6px;
}
.price-container {
  display: flex;
  gap: 4px;
  align-items: center;
}
