.body-layout {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.upperLayout {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
}

.layout-leftChart {
  padding: 10px;
  min-width: 40%;
  width: 55%;
  box-shadow: 4px 1px 10px rgb(233, 233, 233);
  flex-basis: 1 0 50%;
  flex-grow: 1;
  height: 300px;
  background-color: "white";
}

.layout-rightChart {
  background-color: white;
  box-shadow: 1px 1px 10px rgb(233, 233, 233);
  padding: 10px;
  text-align: center;
  min-width: 30%;
  width: 40%;
  height: 300px;
  flex-grow: 1;
  flex-basis: 1 0 50%;
}
