.right svg {
  font-size: 18px !important;
}
.leftHeader svg {
  font-size: 18px !important;
}
.ant-layout-sider-children {
  position: fixed;
}
.ant-layout-sider {
  max-width: 175px;
  min-width: 175px;
}
