/* @media  */

.clientProfile {
  width: 90%;
}
@media only screen and (max-width: 800px) {
  .clientProfile {
    width: 100%;
  }
}
