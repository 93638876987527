@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
/* .site-layout{
  background-color: rgb(65, 160, 238) !important;
  height: 200px;
} */
.ant-layout * {
  font-family: "Inter";
}
* ::-webkit-scrollbar {
  display: none;
}
body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.app{
 
  margin:  0 auto;
  position: relative;
}
.right svg {
  font-size: 18px !important;
}
.leftHeader svg {
  font-size: 18px !important;
}
.ant-layout-sider-children {
  position: fixed;
}
.ant-layout-sider {
  max-width: 175px;
  min-width: 175px;
}

.body-layout {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.upperLayout {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  grid-gap: 30px;
  gap: 30px;
}

.layout-leftChart {
  padding: 10px;
  min-width: 40%;
  width: 55%;
  box-shadow: 4px 1px 10px rgb(233, 233, 233);
  flex-basis: 1 0 50%;
  flex-grow: 1;
  height: 300px;
  background-color: "white";
}

.layout-rightChart {
  background-color: white;
  box-shadow: 1px 1px 10px rgb(233, 233, 233);
  padding: 10px;
  text-align: center;
  min-width: 30%;
  width: 40%;
  height: 300px;
  flex-grow: 1;
  flex-basis: 1 0 50%;
}

.chartContainer > div {
  max-height: 240px;
}
.picker{
  width: 63%;
  max-width: 250px;
}

.picker > .ant-picker-input > input{
  font-size: 12px;
  color : #8f8a8a !important
}
.rightChartContainer > div  { 
  max-height: 240px;
}
.g2-tooltip-list-item{
  display: flex !important;
  justify-content: space-between !important;
}
.left__header > h3 {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  height: 100%;
  font-size: 14px;
  font-family: "Inter", sans-serif;
}
.jobsHeader__left > h2 {
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-weight: 800;
  color: rgb(73, 73, 73);
}

.stars {
  color: rgb(247, 182, 3);
}
.jobsHeader__left > h3 {
  color: rgb(124, 109, 101);
  font-size: 20px;
  font-family: "Inter", sans-serif;
}

.jobsFooter__right > div > span {
  color: rgb(247, 72, 3);
  font-family: "Inter", sans-serif;
  font-size: 11.2px;
}

.jobsHeader__left > p {
  font-size: 12px;
  color: rgb(0, 0, 0);
}

.jobsFooter__right {
  position: relative;
  display: flex;
  flex-direction: column;

  text-align: end;
}

.jobsPosted {
  border: 1px dashed rgb(214, 213, 213);
}

.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}

.ant-card-body {
  /* padding : 0 */
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6px;
}
.price-container {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  align-items: center;
}

/* @media  */

.clientProfile {
  width: 90%;
}
@media only screen and (max-width: 800px) {
  .clientProfile {
    width: 100%;
  }
}

.editAgentProfile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
}
.formInputContainer {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
}

.formInputContainer > Input {
  padding: 10px;
  border-radius: 5px;
}

.formInputContainer > label {
  margin-bottom: 5px;
  font-size: 12px;
  color: #9e9e9e;
}
.addressBar > input {
  padding: 10px 10px;
  font-size: 12px;
}

.addressBar {
  position: relative;
  padding: 20px 0px;
}

.addressBar > label {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  color: gray;
}

.agentProfile {
  width: 90%;
}
.ant-select-selector{
  cursor:  pointer !important;
}

@media only screen and (max-width: 800px) {
  .agentProfile {
    width: 100%;
  }
}

.agentVerification {
  width: 90%;
}
@media only screen and (max-width: 800px) {
  .agentVerification {
    width: 100%;
  }
}

.rejectionReasonsContainer {
  width: 100%;
  background-color: #fff;
  padding: 1rem;
}



.first {
  width: 220px;
  height: 120px;
  max-width: 250px;
  border-radius: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  /* box-shadow: 4px 1px 10px rgb(233 233 233); */
  position: relative;
  border: 1px solid #eeeeee;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.innerStats {
  padding: 10px;
  height: 100%;
  width: 100%;
}
.innerStats > span {
  font-weight: 700;
}

.innerStats > p {
  padding: 0 !important;
  color: rgb(99, 91, 91);
}
.statZero {
  background: linear-gradient(60deg, #66bb6a, #575757);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(76 175 80 / 40%);
}
.statsIcon > span > svg {
  font-size: 25px !important;
}
.statsIcon {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -20px;
  right: 10px;
  display: flex;
  align-items: center;
  color: rgb(255, 255, 255);
}

.statOne {
  background: linear-gradient(60deg, #ffa726, #fb8c00);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(255 152 0 / 40%);
}
.statSecond {
  background: linear-gradient(60deg, #ef5350, #e53935);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(244 67 54 / 40%);
}
.statThird {
  background: linear-gradient(60deg, #26c6da, #00acc1);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(0 172 193 / 40%);
}

.openJobStat {
  background: linear-gradient(60deg, #36e616, #91f163);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(127 255 31 / 40%);
}

.canceledJobStat {
  background: linear-gradient(60deg, #a5a5a5, #c1c1c1);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(128 128 128 / 40%);
}
.userStat {
  background: linear-gradient(60deg, #5088ef, #ca35e5);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(54 104 244 / 40%);
}
.verifiedStat {
  background: linear-gradient(60deg, #50d9ef, #7cd5de);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(244 67 54 / 40%);
}
@media only screen and (max-width: 900px) {
 .statsWrapper{
   justify-content: space-between !important;
 }
}

@media only screen and (max-width: 700px) {
  .first {
    width: 100% !important;
    
    max-width: 100%;
  }
}
.underProcessAgents{
  background: linear-gradient(60deg, #798081, #63adb6);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(244 67 54 / 40%);

}
.toolTipClass{
    max-width: 650px !important;
    overflow-wrap: break-word;
    display: block;
background-color: green;
    @media (min-width: 600px) {
        max-width: 300px;
    }
}


.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filters_children {
  display: flex;
  align-items: center;
}
.form_container {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  justify-content: center;
}
.input_container {
  display: flex;
  align-items: center;
}
.input_label {
  font-size: 1.1rem;
  font-weight: 500;
  min-width: 132px;
}

.value_formatter {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}
.original_rate {
  text-decoration: line-through;
  color: rgb(216, 216, 216);
  font-size: 1rem;
}
.bottom-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chart-container {
  padding: 10px;
  background-color: #fff;
  min-height: 240px;
}
.chart-container > div {
  max-height: 240px;
  background-color: #fff;
}

.loginCard{
    width:  350px;
    padding: 50px;
    box-shadow: 4px 4px 10px rgb(231, 231, 231);
   background-color: white;
   border-radius: 10px;
}

.loginForm{
    width:  100%;
    height: 100vh;
    display:  grid;
    place-items: center;
    background-color:#f3f3f3;

}


