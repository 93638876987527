.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filters_children {
  display: flex;
  align-items: center;
}
.form_container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
}
.input_container {
  display: flex;
  align-items: center;
}
.input_label {
  font-size: 1.1rem;
  font-weight: 500;
  min-width: 132px;
}

.value_formatter {
  display: flex;
  align-items: center;
  gap: 4px;
}
.original_rate {
  text-decoration: line-through;
  color: rgb(216, 216, 216);
  font-size: 1rem;
}
.bottom-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
