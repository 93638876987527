.agentProfile {
  width: 90%;
}
.ant-select-selector{
  cursor:  pointer !important;
}

@media only screen and (max-width: 800px) {
  .agentProfile {
    width: 100%;
  }
}
