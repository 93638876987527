.loginCard{
    width:  350px;
    padding: 50px;
    box-shadow: 4px 4px 10px rgb(231, 231, 231);
   background-color: white;
   border-radius: 10px;
}

.loginForm{
    width:  100%;
    height: 100vh;
    display:  grid;
    place-items: center;
    background-color:#f3f3f3;

}

