.editAgentProfile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}
.formInputContainer {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
}

.formInputContainer > Input {
  padding: 10px;
  border-radius: 5px;
}

.formInputContainer > label {
  margin-bottom: 5px;
  font-size: 12px;
  color: #9e9e9e;
}
.addressBar > input {
  padding: 10px 10px;
  font-size: 12px;
}

.addressBar {
  position: relative;
  padding: 20px 0px;
}

.addressBar > label {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  color: gray;
}
