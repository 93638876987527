.chartContainer > div {
  max-height: 240px;
}
.picker{
  width: 63%;
  max-width: 250px;
}

.picker > .ant-picker-input > input{
  font-size: 12px;
  color : #8f8a8a !important
}
.rightChartContainer > div  { 
  max-height: 240px;
}
.g2-tooltip-list-item{
  display: flex !important;
  justify-content: space-between !important;
}