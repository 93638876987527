@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.left__header > h3 {
  display: flex;
  gap: 10px;
  height: 100%;
  font-size: 14px;
  font-family: "Inter", sans-serif;
}
.jobsHeader__left > h2 {
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-weight: 800;
  color: rgb(73, 73, 73);
}

.stars {
  color: rgb(247, 182, 3);
}
.jobsHeader__left > h3 {
  color: rgb(124, 109, 101);
  font-size: 20px;
  font-family: "Inter", sans-serif;
}

.jobsFooter__right > div > span {
  color: rgb(247, 72, 3);
  font-family: "Inter", sans-serif;
  font-size: 11.2px;
}

.jobsHeader__left > p {
  font-size: 12px;
  color: rgb(0, 0, 0);
}

.jobsFooter__right {
  position: relative;
  display: flex;
  flex-direction: column;

  text-align: end;
}

.jobsPosted {
  border: 1px dashed rgb(214, 213, 213);
}

.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}
