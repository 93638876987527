/* .site-layout{
  background-color: rgb(65, 160, 238) !important;
  height: 200px;
} */
.ant-layout * {
  font-family: "Inter";
}
* ::-webkit-scrollbar {
  display: none;
}
body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
