

.first {
  width: 220px;
  height: 120px;
  max-width: 250px;
  border-radius: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  /* box-shadow: 4px 1px 10px rgb(233 233 233); */
  position: relative;
  border: 1px solid #eeeeee;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.innerStats {
  padding: 10px;
  height: 100%;
  width: 100%;
}
.innerStats > span {
  font-weight: 700;
}

.innerStats > p {
  padding: 0 !important;
  color: rgb(99, 91, 91);
}
.statZero {
  background: linear-gradient(60deg, #66bb6a, #575757);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(76 175 80 / 40%);
}
.statsIcon > span > svg {
  font-size: 25px !important;
}
.statsIcon {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -20px;
  right: 10px;
  display: flex;
  align-items: center;
  color: rgb(255, 255, 255);
}

.statOne {
  background: linear-gradient(60deg, #ffa726, #fb8c00);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(255 152 0 / 40%);
}
.statSecond {
  background: linear-gradient(60deg, #ef5350, #e53935);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(244 67 54 / 40%);
}
.statThird {
  background: linear-gradient(60deg, #26c6da, #00acc1);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(0 172 193 / 40%);
}

.openJobStat {
  background: linear-gradient(60deg, #36e616, #91f163);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(127 255 31 / 40%);
}

.canceledJobStat {
  background: linear-gradient(60deg, #a5a5a5, #c1c1c1);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(128 128 128 / 40%);
}
.userStat {
  background: linear-gradient(60deg, #5088ef, #ca35e5);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(54 104 244 / 40%);
}
.verifiedStat {
  background: linear-gradient(60deg, #50d9ef, #7cd5de);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(244 67 54 / 40%);
}
@media only screen and (max-width: 900px) {
 .statsWrapper{
   justify-content: space-between !important;
 }
}

@media only screen and (max-width: 700px) {
  .first {
    width: 100% !important;
    
    max-width: 100%;
  }
}
.underProcessAgents{
  background: linear-gradient(60deg, #798081, #63adb6);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(244 67 54 / 40%);

}