.toolTipClass{
    max-width: 650px !important;
    overflow-wrap: break-word;
    display: block;
background-color: green;
    @media (min-width: 600px) {
        max-width: 300px;
    }
}

